import { enqueueAlert } from "./alertSlice";
// Import the necessary functions from 'graphql-relay'
import { toGlobalId, fromGlobalId } from "graphql-relay";

interface CreateQueryProps {
  filter: any;
  customFields?: {
    customKey: string;
    newValue: string;
    valueType: "text" | "array";
    secondaryValue?: string;
  }[];
}

export const createQuery = ({ filter, customFields }: CreateQueryProps) => {
  const customFieldsObj = Object.fromEntries(
    customFields?.map((item) => [item.customKey, item]) || []
  );

  return Object.entries(filter)
    .filter(([key, value]) => value)
    .map(([key, value]) => {
      const customObj = customFieldsObj[key];

      if (customObj?.newValue && value) {
        const { newValue, valueType, secondaryValue } = customObj;

        if (valueType === "array" && Array.isArray(value)) {
          return value.map((val) => `${newValue}=${val}`).join("&");
        } else {
          const param = `${newValue}=${value}`;

          if (secondaryValue) {
            return `${param}&${secondaryValue}=${value}`;
          } else {
            return param;
          }
        }
      } else {
        return `${key}=${value}`;
      }
    })
    .join("&");
};

// catching validation/api errors from API
export const apiErrorCatcher = (error: any, dispatch: any) => {
  let eResponse, eData;

  if (Array.isArray(error)) {
    // this is specific to graphql. error [] will have objects with messages
    eData = error[0].messages;
  } else {
    eResponse = error?.response;
    eData = eResponse?.data;
  }

  const errorMessages = [];

  if (Array.isArray(eData)) {
    for (const key in eData) {
      const value = eData[key];

      if (Array.isArray(value)) {
        errorMessages.push(...value);
      } else if (typeof value === "object" && value !== null) {
        // If the value is an object (and not null), handle it accordingly
        // Assuming you want to concatenate all object values into errorMessages
        for (const innerKey in value) {
          if (Array.isArray(value[innerKey])) {
            // If the inner value is an array, spread its items into errorMessages
            errorMessages.push(...value[innerKey]);
          } else {
            // If the inner value is not an array, just push the value
            errorMessages.push(value[innerKey]);
          }
        }
      } else {
        errorMessages.push(value);
      }
    }
  } else {
    for (const key in eData) {
      const value = eData[key];
      errorMessages.push(value);
    }
  }

  let errorMessage = error.message;

  if (errorMessages.length > 0) {
    errorMessage = errorMessages.join(", ");
  }

  dispatch(enqueueAlert({ severity: "error", message: errorMessage }));
};

// Function to convert a number to a global identifier
export const numberToGlobalId = (type: string, number: number) => {
  // Use the toGlobalId function to create a global identifier
  return toGlobalId(type, number.toString());
};

// Function to convert a global identifier back to a number
export const globalIdToNumber = (globalId: string) => {
  // Use the fromGlobalId function to parse the global identifier
  const { id } = fromGlobalId(globalId);
  // Assuming that the ID stored in the global identifier is a number
  // You may need to add validation or handle different types accordingly
  return parseInt(id, 10);
};

export const handleFileDownload = (response: any) => {
  // Create a Blob object from the binary data
  const blob = new Blob([response.data], {
    type: response.headers["content-type"],
  });

  // Create a download link
  const downloadLink = document.createElement("a");
  downloadLink.href = window.URL.createObjectURL(blob);
  // downloadLink.download = "exported_data.zip"; // Specify the filename you want

  // Append the download link to the body
  document.body.appendChild(downloadLink);

  // Trigger the download
  downloadLink.click();

  // Remove the download link from the body
  document.body.removeChild(downloadLink);
};
