// Reducers
import errorsReducer from "./errors";
import tablesReducer from "./tables";
import threadsReducer from "./threads";
import requestsReducer from "./requests";
import programsReducer from "./programs";
import supervisorReducer from "./supervisors";
import applicantReducer from "./applicants";
import authReducer from "./authentication";
import companiesReducer from "./companies";
import registrationsReducer from "./registrations";
import evaluationsReducer from "./evaluations";
import activitiesReducer from "./activities";
import activityRegistrationReducer from "./activityRegistration";
import UsherReducer from "./ushers";
import communityCenterReducer from "./communityCenters";

const rootReducer = {
  rootAuth: authReducer,
  rootTables: tablesReducer,
  rootErrors: errorsReducer,
  rootThreads: threadsReducer,
  rootApplicants: applicantReducer,
  rootSupervisors: supervisorReducer,
  rootPrograms: programsReducer,
  rootRequests: requestsReducer,
  rootCompanies: companiesReducer,
  rootRegistrations: registrationsReducer,
  rootEvaluations: evaluationsReducer,
  rootActivities: activitiesReducer,
  rootActivityRegistration: activityRegistrationReducer,
  rootUshers: UsherReducer,
  rootCommunityCenters: communityCenterReducer,
};

export default rootReducer;
