import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, message, notification } from "antd";
import i18n from "config/i18n";
import moment from "moment";
import { defaultColors } from "components/common/Materialui/themes/main/default";
const { confirm, info, warning, error } = Modal;

export const customMoment = (date = moment(), format = null) => {
  return moment.utc(date, format).utcOffset("+0000", true);
};

export const isValidValue = (value) => {
  return ![undefined, null, ""].includes(value);
};

export const getRandomInt = (min = 65, max = 90) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const generateRandomString = (length, from_code = 65, to_code = 90) => {
  let value = "";
  for (let i = 0; i < length; i++) {
    const chr_code = getRandomInt(from_code, to_code);
    const chr_value = String.fromCharCode(chr_code);
    value += chr_value;
  }
  return value;
};

export const copyText = (text) => {
  navigator.clipboard.writeText(text);
};

export const showError = (title, content) => {
  error({
    title,
    icon: <ExclamationCircleOutlined />,
    content: content,
    centered: true,
  });
};

export const showWarning = (title, content) => {
  warning({
    title,
    icon: <ExclamationCircleOutlined />,
    content: content,
    centered: true,
  });
};

export const showConfirm = (
  title,
  content,
  onOk = () => {},
  onCancel = () => {}
) => {
  confirm({
    title,
    icon: <ExclamationCircleOutlined />,
    content: content,
    onOk() {
      onOk();
    },
    onCancel() {
      onCancel();
    },
    centered: true,
  });
};

export const showInfo = (title, content, onOk = () => {}) => {
  info({
    title,
    icon: <ExclamationCircleOutlined />,
    content: content,
    centered: true,
    onOk: onOk,
  });
};

export const openNotification = (type, message, description) => {
  notification[type]({
    message: message,
    description: description,
    duration: 5,
    // placement: "topLeft"
  });
};

export const openMessage = (type, content) => {
  message[type](content);
};

export const downloadDataAsCsv = (name, data = [], columns = null) => {
  let csvContent = "data:text/csv;charset=utf-8,";

  let header = "";
  if (isValidValue(columns)) {
    Object.keys(columns).map((Key) => {
      return (header += columns[Key] + ",");
    });
  } else {
    if (data.length > 0) {
      Object.keys(data[0]).map((Key) => {
        return (header += Key + ",");
      });
    }
  }

  csvContent += header.substring(-1) + "\r\n";

  data.forEach((rowArray) => {
    let row = "";
    if (isValidValue(columns)) {
      Object.keys(columns).map((Key) => {
        if (typeof rowArray[Key] !== "object")
          row += (isValidValue(rowArray[Key]) ? rowArray[Key] : "") + ",";
        return null;
      });
    } else {
      Object.keys(rowArray).map((Key) => {
        if (typeof rowArray[Key] !== "object" || !isValidValue(rowArray[Key]))
          row += (isValidValue(rowArray[Key]) ? rowArray[Key] : "") + ",";
        return null;
      });
    }

    csvContent += row + "\r\n";
  });

  let encodedUri = encodeURI(csvContent);
  let link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", name);
  link.click();
};

export const toSlugFormat = (value) => {
  value = value.replace(/[^1-9a-zA-Z١-٩ا-ي -]/g, ""); // remove special characters
  value = value.replace(/[^1-9a-zA-Z١-٩ا-ي]/g, "-"); // remove spaces
  let last_value = [];

  let is_duplicate = false;
  for (let i = 0; i < value.length; i++) {
    if (value.charAt(i) === "-") {
      if (!is_duplicate) {
        last_value.push(value.charAt(i));
        is_duplicate = true;
      }
    } else {
      last_value.push(value.charAt(i));
      is_duplicate = false;
    }
  }

  if (last_value[last_value.length - 1] === "-") {
    last_value.pop();
  }

  last_value = last_value.join("");

  return last_value;
};

export const disableFutureDates = (current, disable_today) => {
  let custom_date = moment().format("DD-MM-YYYY");
  if (disable_today) {
    return current && current <= moment(custom_date, "DD-MM-YYYY");
  }
  return current && current < moment(custom_date, "DD-MM-YYYY");
};

export const validateProgramStaffPermission = (
  programDepartment,
  programOtherDepartment,
  staffDepartment
) => {
  const departments = [programDepartment];

  if (Array.isArray(programOtherDepartment)) {
    departments.push(...programOtherDepartment);
  } else {
    departments.push(programOtherDepartment);
  }

  return departments.includes(staffDepartment);
};

export const convertApolloNodeToFlatArray = (data) => {
  if (!data) return [];
  return data.map((item) => item.node);
};

export function getLanguageDirection() {
  const activeLanguage = i18n.language;
  // You may need to customize this logic based on your language configuration
  return activeLanguage === "ar" ? "rtl" : "ltr";
}

export const convertToDateStr = (date) => {
  const dateObj = moment(date);
  return dateObj.format("DD/MM/YYYY");
};

export const removeEmpty = (obj) => {
  return Object.entries(obj)
    .filter(([_, v]) => v !== "" && v !== null && v !== undefined)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
};

export const convertToMonthYearStr = (date) => {
  try {
    const dateObj = moment(date);
    return dateObj.format("M-YYYY");
  } catch {
    return null;
  }
};

export const convertStrMonthYearToDate = (monthYearStr) => {
  // Parse the Month-Year string in "M-YYYY" format
  try {
    const dateObj = moment(monthYearStr, "M-YYYY");
    return dateObj;
  } catch {
    return null;
  }
};

export const setKeyValueStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const getKeyStorage = (key) => {
  try {
    if (typeof window !== "undefined" && window.localStorage) {
      return localStorage.getItem(key);
    } else {
      console.warn("LocalStorage is not available.");
      return null;
    }
  } catch (error) {
    if (error.name === "SecurityError") {
      console.error(
        "SecurityError: Unable to access localStorage. Check your browser settings."
      );
    } else {
      console.error("Unexpected error accessing localStorage:", error);
    }
    return null;
  }
};

export const deleteKeyStorage = (key) => {
  const keyExists = getKeyStorage(key);
  if (keyExists) {
    localStorage.removeItem(key);
  }
};

export const civilIDValidation = (civilID) => {
  const FirstDigit = parseInt(civilID.charAt(0));
  const year = parseInt(civilID.substring(1, 3));
  const month = parseInt(civilID.substring(3, 5));
  const day = parseInt(civilID.substring(5, 7));
  return (
    (FirstDigit !== 2 && FirstDigit !== 3) ||
    year < 0 ||
    year > 99 ||
    month < 1 ||
    month > 12 ||
    day < 1 ||
    day > 31
  );
};

export const dobCivilIDValidation = (DOB, CivilID) => {
  const birthDate = new Date(DOB);

  const fullYear = birthDate.getFullYear();
  const twoDigitsYear = parseInt(fullYear.toString().substr(-2));
  const month = birthDate.getMonth() + 1; // getMonth() is zero-based, thus +1
  const day = birthDate.getDate();

  if (fullYear < 2000 && parseInt(CivilID.charAt(0)) !== 2) return false;
  if (fullYear >= 2000 && parseInt(CivilID.charAt(0)) !== 3) return false;
  if (twoDigitsYear !== parseInt(CivilID.toString().substring(1, 3)))
    return false;
  if (month !== parseInt(CivilID.toString().substring(3, 5))) return false;
  if (day !== parseInt(CivilID.toString().substring(5, 7))) return false;

  return true;
};

export const removeEmptyFields = (list) => {
  // this receive a list of objects and remove empty attribute inside each object
  return list.map((item) => {
    const newItem = {};
    Object.entries(item).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        newItem[key] = value;
      }
    });
    return newItem;
  });
};

export const deepCopy = (obj) => {
  if (obj) {
    return JSON.parse(JSON.stringify(obj));
  }
};

export const getMonthsList = () => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months;
};

export const accessOnlyEn = (user) => {
  if (!user) return false;

  return (
    user?.is_loyac_staff ||
    user?.is_community_center ||
    user?.is_company ||
    user?.is_thirdParty_supervisor
  );
};

/**
 * By default the avatar bf color is blue, but if we call this function,
 * it will make the avatar bg color the loyac main green color.
 */
export const changeAvatarBgColor = () => {
  const svgElements = document.querySelectorAll('[id="Color/Palette/Blue-01"]');
  svgElements.forEach((element) => {
    element.setAttribute("fill", defaultColors.primary);
  });
};
/**
 * This function is only used in gettPublicPrograms Slice,
 * NOTE: Please consider the conditions before using it for other
 * purposes
 */
export const convertArraysToStrings = (obj) => {
  const newObj = {};
  for (const key in obj) {
    const value = obj[key];
    if (Array.isArray(obj[key])) {
      newObj[key] = JSON.stringify(value);
    } else if (value === "true" || value === "false") {
      newObj[key] = JSON.parse(value);
    } else if (
      !isNaN(value) &&
      typeof value === "string" &&
      value.trim() !== ""
    ) {
      newObj[key] = Number(value);

      // Handle all other values
    } else {
      newObj[key] = value;
    }

    if (key === "year") newObj[key] = value.toString();
  }
  return newObj;
};
