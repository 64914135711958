import React from "react";
import CustomContainer from "components/common/Materialui/Container/CustomContainer";
import {
  Divider,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { muiGridContainerSpacing } from "components/common/js/constants";
import { defaultColors } from "components/common/Materialui/themes/main/default";
import { ReactComponent as FacebookIcon } from "./Icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "./Icons/instagram.svg";
import { ReactComponent as Tiktok } from "./Icons/tiktok.svg";
import { ReactComponent as Youtube } from "./Icons/youtube.svg";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const { t } = useTranslation();

  const footerLinks = [
    { label: t("footer.staffLogin"), href: "/en/login/staff" },
    { label: t("footer.supervisorLogin"), href: "/en/login/supervisor" },
    { label: t("footer.companyLogin"), href: "/en/login/company" },
  ];

  const iconStyles = {
    minWidth: "30px",
  };

  const socialLinks = [
    {
      label: t("footer.facebook"),
      href: "https://www.facebook.com/LoyacKW",
      icon: (
        <ListItemIcon sx={iconStyles}>
          <FacebookIcon />
        </ListItemIcon>
      ),
    },
    {
      label: t("footer.instagram"),
      href: "https://www.instagram.com/loyackuwait/",
      icon: (
        <ListItemIcon sx={iconStyles}>
          <InstagramIcon />
        </ListItemIcon>
      ),
    },
    {
      label: t("footer.youtube"),
      href: "https://www.youtube.com/@LoyacKuwait/",
      icon: (
        <ListItemIcon sx={iconStyles}>
          <Youtube />
        </ListItemIcon>
      ),
    },
    {
      label: t("footer.tiktok"),
      href: "https://www.tiktok.com/@loyackuwait/",
      icon: (
        <ListItemIcon sx={iconStyles}>
          <Tiktok />
        </ListItemIcon>
      ),
    },
  ];

  const contactUsLinks = [
    {
      key: t("footer.addressKey"),
      label: t("footer.addressValue"),
      href: "#facebook",
    },
    {
      key: t("footer.poBoxKey"),
      label: t("footer.poBoxValue"),
      href: "#twitter",
    },
    {
      key: t("footer.phoneKey"),
      label: t("footer.phoneValue"),
      href: "#linkedin",
    },
    {
      key: t("footer.emailKey"),
      label: t("footer.emailValue"),
      href: "#linkedin",
    },
  ];

  const FooterHeader = ({ text }: any) => {
    return (
      <Typography variant="h5" sx={{ color: defaultColors.darkGreen }}>
        {text}
      </Typography>
    );
  };

  const listButtonSytles = {
    p: 0,
    "&:hover": {
      backgroundColor: "transparent",
      color: defaultColors.darkGreen,
    },
  };

  return (
    <footer
      className="footer"
      style={{
        backgroundColor: defaultColors.primary,
        color: defaultColors.light0,
      }}
    >
      <CustomContainer maxWidth="xl">
        <Grid container spacing={muiGridContainerSpacing}>
          {/* About Section */}
          <Grid item xs={12} md={4}>
            <FooterHeader text={t("footer.about")} />
            <nav aria-label="quick links">
              <List>
                <ListItem sx={{ p: 0 }}>
                  <ListItemText primary={t("footer.aboutText")} />
                </ListItem>
              </List>
            </nav>
          </Grid>

          {/* Quick Links Section */}
          <Grid item xs={12} md={2}>
            <FooterHeader text={t("footer.quickLinks")} />
            <nav aria-label="quick links">
              <List>
                {footerLinks.map((link) => (
                  <ListItemButton
                    component="a"
                    href={link.href}
                    sx={listButtonSytles}
                    key={link.label}
                    disableRipple
                  >
                    <ListItemText primary={link.label} />
                  </ListItemButton>
                ))}
              </List>
            </nav>
          </Grid>

          {/* Social Media Links Section */}
          <Grid item xs={12} md={2}>
            <FooterHeader text={t("footer.followOn")} />

            <nav aria-label="social media links">
              <List>
                {socialLinks.map((link) => (
                  <ListItemButton
                    component="a"
                    href={link.href}
                    target="_blank"
                    sx={listButtonSytles}
                    key={link.label}
                  >
                    {link.icon}

                    <ListItemText primary={link.label} />
                  </ListItemButton>
                ))}
              </List>
            </nav>
          </Grid>

          {/* Contact us section */}
          <Grid item xs={12} md={4}>
            <FooterHeader text={t("footer.contactUs")} />
            <nav aria-label="contact us links">
              <List>
                {contactUsLinks.map((link) => (
                  <ListItem sx={{ p: 0 }} key={link.label}>
                    <ListItemText>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {link.key}
                      </Typography>
                      <Typography>{link.label}</Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </nav>
          </Grid>

          {/* Footer Bottom Section */}
          <Grid item xs={12}>
            <Divider sx={{ borderColor: defaultColors.light0 }} />
            <Typography variant="body1" p={2} textAlign="center">
              {t("footer.allRights", { currentYear })}
            </Typography>
          </Grid>
        </Grid>
      </CustomContainer>
    </footer>
  );
};

export default Footer;
