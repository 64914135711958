import { ConfigProvider } from "antd";
import "antd/dist/antd.variable.min.css";

import { Suspense } from "react";

import ReactGA from "react-ga4";

import MainRoute from "routes";

import Navbar from "./components/navigation/navbar";
import LoadingPage from "components/common/components/loading";
import Footer from "components/footer/Footer";

const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTIC_ID; // OUR_TRACKING_ID

if (TRACKING_ID) {
  ReactGA.initialize(TRACKING_ID);
}

ConfigProvider.config({
  theme: {
    primaryColor: "#00a84f",
  },
});

const App = () => {
  return (
    <div className="app">
      <div className="main-content">
        <Navbar />
        <Suspense fallback={<LoadingPage />}>
          <MainRoute />
        </Suspense>
      </div>
      <Footer />
    </div>
  );
};

export default App;
