// Actions
import {
  SET_APPLICANT,
  SET_APPLICANT_COMPLEX,
  SET_COMMUNITY_CENTER,
  SET_COMPANY,
  SET_CURRENT_USER,
  SET_PARENT,
  SET_STAFF,
  SET_SUPERVISOR,
  SET_USER_LOADING,
} from "../actions/actionTypes";

const initialState = {
  user: null,
  userLoading: true,
  company: null,
  companyLoading: true,
  applicant: null,
  applicantLoading: true,
  applicantComplex: null,
  applicantComplexLoading: true,
  loyacStaff: null,
  staffLoading: true,
  parent: null,
  parentLoading: true,
  supervisor: null,
  supervisorLoading: true,
  communitycenter: null,
  communitycenterLoading: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_LOADING:
      return {
        ...state,

        userLoading: action.payload,
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        user: action.payload,
        userLoading: false,
      };
    case SET_STAFF:
      return {
        ...state,
        loyacStaff: action.payload,
        staffLoading: false,
      };
    case SET_SUPERVISOR:
      return {
        ...state,
        supervisor: action.payload,
        supervisorLoading: false,
      };
    case SET_COMPANY:
      return {
        ...state,
        company: action.payload,
        companyLoading: false,
      };
    case SET_APPLICANT:
      return {
        ...state,
        applicant: action.payload,
        applicantLoading: false,
      };
    case SET_APPLICANT_COMPLEX:
      return {
        ...state,
        applicantComplex: action.payload,
        applicantComplexLoading: false,
      };
    case SET_COMMUNITY_CENTER:
      return {
        ...state,
        communitycenter: action.payload,
        communitycenterLoading: false,
      };
    case SET_PARENT:
      return {
        ...state,
        parent: action.payload,
        parentLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
