import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";
import { defaultColors } from "./common/Materialui/themes/main/default";
import dayjs from "dayjs";
export const customTextButton: SxProps<Theme> = {
  color: defaultColors.dark0,
  fontWeight: 600,
  textTransform: "none",
  justifyContent: "start",
  fontSize: "1rem",
  width: "100%",
};

type ISX = SxProps<Theme>;
export const navbarItemStyle: ISX = {
  color: defaultColors.dark0,
  width: "100%",
  lineHeight: "1.75rem",
  paddingY: "0.7rem",
  fontWeight: 600,
  textTransform: "none",
};

export const defaultTextFieldProps = (name: string, required = true) => ({
  size: "small",
  name,
  fullWidth: true,
  required,
  InputProps: {
    required,
  },
});

export const defaultDatePickerProps = (
  name: string,
  label: string,
  required = true
) => ({
  minDate: dayjs(new Date()),
  format: "YYYY-M-D",
  slotProps: {
    textField: {
      name,
      label,
      size: "small",
      required,
    },
  },
});
