// This consists of public routes
import { lazy } from "react";

const LandingPage = lazy(() => import("../components/navigation/landing"));
const InitiativeFAQs = lazy(
  () => import("../components/LSV/InitiativeDetails/FAQs")
);
const FAQ = lazy(() => import("../components/faq"));
const ForgetPasswordForm = lazy(
  //@ts-ignore
  () => import("../components/common/forms/ForgetPasswordForm")
);

const ActivityDetails = lazy(
  //@ts-ignore
  () => import("../components/activities/activityDetails")
);
const InitiativeDetails = lazy(
  () => import("../components/LSV/InitiativeDetails")
);

// used for authenticated and public
const ProgramDetails = lazy(
  () => import("../components/programs/programDetails")
);

const PublicProgramList = lazy(
  () => import("../components/programs/programsList/PublicProgramList")
);
const ProgramSurveys = lazy(
  //@ts-ignore
  () => import("../components/programs/programSurveys")
);
const ProductList = lazy(
  () => import("../components/programs/programsList/ProductList")
);
const Checkout = lazy(
  () => import("../components/programs/programsList/ecommerce/Checkout")
);
const PaymentConfirmation = lazy(
  () =>
    import("../components/programs/programDetails/sections/PaymentConfirmation")
);
//@ts-ignore
const CommunityCenterSignup = lazy(
  () => import("../components/community-service/auth/signup/index")
);
//@ts-ignore
const ParentSignup = lazy(() => import("../components/parents/auth/signup"));
//@ts-ignore
const EventDetails = lazy(
  () => import("../components/events/applicants/event/EventDetails")
);
const EventsList = lazy(
  () => import("../components/events/applicants/event/EventsList")
);
//@ts-ignore
const CompanySignup = lazy(() => import("../components/companies/auth/signup"));

export const publicRoutes = [
  { path: "accounts", Component: LandingPage, key: "accounts" },
  {
    path: "parent/signup",
    Component: ParentSignup,
    key: "parent-signup",
  },
  {
    path: "company/signup",
    Component: CompanySignup,
    key: "company-signup",
  },
  {
    path: "initiatives/:initiativeID/faq",
    Component: InitiativeFAQs,
    key: "initiatives-list",
  },
  {
    path: "FAQ",
    Component: FAQ,
    key: "faq",
  },
  {
    path: "reset/password/:token/:user_type",
    Component: ForgetPasswordForm,
    key: "reset-password",
  },
  {
    //TODO: check arabic for this
    path: "activities/:activityID",
    Component: ActivityDetails,
    key: "activity-detail",
  },
  {
    //TODO: check the arabic
    path: "initiatives/:initiativeID",
    Component: InitiativeDetails,
    key: "initiative-detail",
  },
  {
    path: "community_center/signup",
    Component: CommunityCenterSignup,
    key: "community-center-signup",
  },
  {
    path: "programs/:programID/surveys/:surveyID",
    Component: ProgramSurveys,
    key: "program-survey",
  },
  {
    path: "programs/:programID",
    Component: ProgramDetails,
    key: "program-detail",
  },
  {
    path: "/",
    Component: PublicProgramList,
    key: "programs",
  },
  {
    path: "products",
    Component: ProductList,
    key: "products",
  },
  {
    path: "checkout",
    Component: Checkout,
    key: "checkout",
  },
  {
    path: "confirmation",
    Component: PaymentConfirmation,
    key: "confirmation",
  },
  {
    path: "event-details/:slug",
    Component: EventDetails,
    key: "event-details",
  },
  {
    path: "events",
    Component: EventsList,
    key: "events",
  },
];
