import { lazy } from "react";

const LoginForm = lazy(() => import("../components/common/forms/LoginForm"));
//@ts-ignore
// const CompanySignup = lazy(() => import("../components/companies/auth/signup"));

//@ts-ignore
const ApplicantSignup = lazy(
  () => import("../components/applicants/auth/signup")
);
/**
 *
 * LoggedIn Users cannot access these routes. Tf they do so, it will show
 * them 404 not found
 */
export const unauthenticatedRoutes = [
  {
    path: "login/:userType",
    Component: LoginForm,
    key: "login",
  },
  // {
  //   path: "company/signup",
  //   Component: CompanySignup,
  //   key: "company-signup",
  // },

  {
    path: "applicant/signup",
    Component: ApplicantSignup,
    key: "applicant-signup",
  },
];
