import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_DOMAIN,
});

instance.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error) => {
    let status = ((error || {}).response || {}).status;
    if (status === 401) {
      // If axios experience 401 navigate to home/login page
      localStorage.clear();
      window.location.href = "/accounts/?logout=true";
    }

    return Promise.reject(error);
  }
);

export default instance;

export async function httpRequestWithAuth(url, method, data, params, headers) {
  let feedback;
  try {
    feedback = await instance(url, {
      method: method,
      data,
      params,
      headers,
    })
      .then((res) => {
        return { status: true, data: res.data };
      })
      .catch((error) => {
        return {
          status: false,
          error_feedback: error.response.data || {
            non_field_errors: "Ops, Something went wrong",
          },
        };
      });
  } catch (e) {
    feedback = {
      status: false,
      fatal_error: true,
      msg: "Something went wrong, try again",
      error_feedback: { non_field_errors: "Ops, Something went wrong" },
    };
  }

  return feedback;
}

export async function httpRequestWithoutAuth(
  url,
  method,
  data,
  params,
  headers = {}
) {
  let feedback;
  try {
    feedback = await instance(url, {
      method: method,
      data,
      params,
      headers,
    })
      .then((res) => {
        return { status: true, msg: res.statusText, data: res.data };
      })
      .catch((error) => {
        return {
          status: false,
          msg: error.response.status,
          error_feedback: error.response.data || {
            non_field_errors: "Ops, Something went wrong",
          },
        };
      });
  } catch (e) {
    feedback = {
      status: false,
      fatal_error: true,
      msg: "Something went wrong, try again",
      error_feedback: { non_field_errors: "Ops, Something went wrong" },
    };
  }

  return feedback;
}
