import instance from "./instance";
import * as actionTypes from "./actionTypes";
import { SetCurrentUser } from "./authentication";
export const setSupervisor = (supervisor) => ({
  type: actionTypes.SET_SUPERVISOR,
  payload: supervisor,
});

export const GetSupervisor = (userID) => async (dispatch) => {
  try {
    const supervisorCode = localStorage.getItem("supervisor_code");
    const res = await instance.get(
      `/supervisor/${userID}/?supervisor_code=${supervisorCode}`
    );
    const supervisor = res.data;
    dispatch(setSupervisor(supervisor));
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const GetSupervisorAssignedCompany =
  (userID, givenSupervisorCode,programID) => async () => {
    try {
      const supervisorCode =
        givenSupervisorCode || localStorage.getItem("supervisor_code");
      const response = await instance.get(
        `/supervisor/${userID}/companies/?supervisor_code=${supervisorCode}&program=${programID}`
      );
      return response.data;
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const AssignSupervisorToCompany = (user_obj, values) => async () => {
  try {
    const res = await instance.post(`supervisor/companies/assign/`, {
      ...user_obj,
      ...values,
    });
    window.location.reload();
    return res.data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const GetSupervisorHistory =
  (userID, supervisorCode) => async (dispatch) => {
    try {
      const res = await instance.get(
        `/supervisor/${userID}/?supervisor_code=${supervisorCode}`
      );
      const supervisor = res.data;
      dispatch(setSupervisor(supervisor));
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const GetSupervisorUsers = () => async (dispatch) => {
  try {
    const res = await instance.get(`/supervisors/add/`);
    const supervisors = res.data;
    return supervisors;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const fetchProgramSupervisorEvaluation =
  (supervisorCode, programID) => async () => {
    try {
      const res = await instance.get(
        `/supervisor/evaluation/${supervisorCode}/${programID}/`
      );
      const supervisors = res.data;
      return supervisors;
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };
export const AddUserSupervisor = (values) => async (dispatch) => {
  try {
    const res = await instance.post(`/supervisors/add/`, values);
    const supervisors = res.data;
    return supervisors;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const UpdateUserSupervisor =
  (values, supervisor) => async (dispatch) => {
    try {
      const res = await instance.put(`/supervisors/add/`, {
        ...values,
        supervisor_id: supervisor.id,
      });
      const supervisors = res.data;
      return supervisors;
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const GetAllSupervisors = () => async (dispatch) => {
  try {
    const res = await instance.get(`/supervisors/`);
    const supervisors = res.data;

    dispatch({ type: actionTypes.GET_SUPERVISORS, payload: supervisors });
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const addSupervisor = (userData) => async () => {
  try {
    let formData = new FormData();
    Object.keys(userData).forEach((key) => {
      key !== "user"
        ? formData.append(key, userData[key])
        : formData.append(key, JSON.stringify(userData[key]));
    });

    if (typeof formData.get("profile_image") === "string")
      formData.delete("profile_image");
    await instance.post(`/supervisor/`, formData);
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const updateSupervisor =
  (userID, supervisorData, history) => async (dispatch) => {
    try {
      const supervisorCode = localStorage.getItem("supervisor_code");

      let formData = new FormData();
      Object.keys(supervisorData).forEach((key) => {
        key !== "user" || key !== "program"
          ? formData.append(key, supervisorData[key])
          : formData.append(key, JSON.stringify(supervisorData[key]));
      });

      // Remove fields if they hadn't been update, because their format will be wrong.
      if (typeof formData.get("profile_image") === "string")
        formData.delete("profile_image");

      const res = await instance.put(
        `/supervisor/${userID}/?supervisor_code=${supervisorCode}`,
        formData
      );
      const response = res.data;
      const supervisor = response.supervisor.data;

      if ("token" in response) {
        const token = response["token"];
        dispatch(SetCurrentUser(token));
      }
      dispatch(setSupervisor(supervisor));

      if (history) history.push(`/supervisor/${userID}/profile/`);
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const updateSupervisorProgram =
  (userID, supervisorData, history) => async () => {
    try {
      await instance.put(`/supervisor/${userID}/`, supervisorData);

      if (history)
        history.push(
          `/programs/${
            supervisorData.program[supervisorData.program.length - 1]
          }/supervisors/`
        );
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const getSupervisorForms = (programID) => async (dispatch) => {
  try {
    const res = await instance.get(`/supervisor/form/${programID}/`);
    const forms = res.data;
    dispatch({ type: actionTypes.GET_FORMS, payload: forms });
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const addSupervisorForm = (formData, history) => async () => {
  try {
    await instance.post("/supervisor/form/", formData);
    if (history)
      history.push({
        pathname: `/programs/${formData["program"]}/visit/view/`,
        state: { state: formData },
      });
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const updateSupervisorForms = (programID, data, history) => async () => {
  try {
    await instance.put(`/supervisor/form/${programID}/`, data);
    if (history)
      history.push({
        pathname: `/programs/${programID}/visit/view/`,
        state: { state: data },
      });
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const createStaffSupervisorEvaluation = (data, history) => async () => {
  try {
    await instance.post(`supervisor/evaluation/`, data);
    if (history)
      history.push(`/program/${data.program}/supervisor/evaluations/view/`);
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const updateStaffSupervisorEvaluation =
  (evaluationID, data, history) => async () => {
    try {
      await instance.put(`supervisor/evaluation/${evaluationID}/`, {
        supervisor: data.supervisor.id,
        commitment: data.commitment,
        intern_communication: data.intern_communication,
        loyac_communication: data.loyac_communication,
        company_communication: data.company_communication,
        computer_use: data.computer_use,
        percentage: data.percentage,
        professionalism: data.professionalism,
        problem_handling: data.problem_handling,
        evaluation_date: data.evaluation_date,
        comments: data.comments,
      });
      if (history)
        history.push({
          pathname: `/supervisor/evaluation/view/`,
          state: { evaluation: data, programID: data.program },
        });
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };
