import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { forgetPassword, subscribeEmail } from "redux/actions";

import instance from "redux/actions/instance";

function useLogoutState() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [settingsLoading, setSettingsLoading] = useState(false);

  const fetchUserEmailStatus = async (email) => {
    setSettingsLoading(true);
    try {
      const { data } = await instance.put(
        "v2/applicant/mailchimp/subscription/",
        {
          email,
        }
      );
      return data.status;
    } catch (error) {
      return false;
    } finally {
      setSettingsLoading(true);
    }
  };

  const saveUserEmailStatus = async (status, email) => {
    setSettingsLoading(true);
    const res = await dispatch(subscribeEmail(email, status));
    if (res !== "error") {
      setSettingsLoading(false);
      return res.data.status;
    } else {
      setSettingsLoading(false);
      return false;
    }
  };

  const changePassword = async (email) => {
    const res = await dispatch(forgetPassword({ email, reset_type: "change" }));
    if (res === "success") {
      confirmAlert({
        message: t("common.resetPassword"),
        buttons: [
          {
            label: t("common.notedButton"),
            className: "btn loyac-btn",
          },
        ],
      });
    }
  };
  return {
    settingsLoading,
    fetchUserEmailStatus,
    setSettingsLoading,
    saveUserEmailStatus,
    changePassword,
  };
}

export default useLogoutState;
