import { CircularProgress } from "@mui/material";
import { Redirect, Route } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/reducers/hooks";
import { setPrevLocation } from "redux/slices/locationSlice";

const UnProtectedRoute = ({ component: Component, ...rest }: any) => {
  const dispatch = useAppDispatch();
  const { user, userLoading } = useAppSelector(({ rootAuth }) => rootAuth);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (userLoading) return <CircularProgress />;
        if (!user) {
          return <Component {...props} />;
        } else {
          dispatch(setPrevLocation(props.location));
          return (
            <Redirect
              // Note this goes to /permission-denied if user is logged in
              to={{
                // @ts-ignore
                state: { ...props.location.state, from: props.location },
                pathname: "/permission-denied",
              }}
            />
          );
        }
      }}
    />
  );
};

export default UnProtectedRoute;
