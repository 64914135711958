import { gql } from "@apollo/client";

// Get registration with limited data
export const GET_REGISTRATION_LIMITED = gql`
  query GetRegistration($id: ID!) {
    # Define the variable $id and its type
    registration(id: $id) {
      registeredOn
      status
      registrationFeesPaid
      paid
      id
      pk
      monthChoice {
        id
        pk
        month
      }
      coupon {
        isActive
        title
        code
        isPercentage
        amount
      }
      firstInterviewResult {
        sponsor
        discount {
          id
          pk
          title
          isPercentage
          amount
        }
      }
      internshipPassVolunteering
      isVolunteeringPrice
    }
  }
`;

export const MUTATION_ADD_REGISTRATION_COUPON = gql`
  mutation MutationAddRegistrationCoupon($id: ID!, $code: String!) {
    # Define the variable $id and its type
    addCouponRegistration(input: { id: $id, code: $code }) {
      obj {
        registeredOn
        status
        paid
        registrationFeesPaid
        id
        pk
        coupon {
          isActive
          title
          code
          isPercentage
          amount
        }
        firstInterviewResult {
          sponsor
          discount {
            id
            pk
            title
            isPercentage
            amount
          }
        }
      }
      errors {
        messages
      }
    }
  }
`;

export const MUTATION_REMOVE_REGISTRATION_COUPON = gql`
  mutation MutationRemoveRegistrationCoupon($id: ID!) {
    # Define the variable $id and its type
    removeCouponRegistration(id: $id) {
      obj {
        registeredOn
        status
        registrationFeesPaid
        paid
        id
        pk
        coupon {
          isActive
          title
          code
          isPercentage
          amount
        }
        firstInterviewResult {
          sponsor
          discount {
            id
            pk
            title
            isPercentage
            amount
          }
        }
      }
    }
  }
`;

// Get registration with limited data
export const GET_COMPANY_ACCESS_PROFILE = gql`
  query GetCompanyAllowedApplicantProfile($applicantPk: Int!) {
    isCompanyAllowedApplicantProfile(applicantPk: $applicantPk)
  }
`;

export const GET_PAYMENT_REGISTRATION = gql`
  query GetPaymentRegistration($id: ID!) {
    # Define the variable $id and its type
    registration(id: $id) {
      applicant {
        fullName
        mobileNumber
        user {
          email
        }
      }
      isParent
      parent {
        user {
          email
        }
        name
        mobileNumber
      }

      paid
      registrationFeesPaid
      internshipPassVolunteering
      program {
        name
        programType
      }
      trackId
      status
      paymentMethod
      registrationFeesPaymentMethod
      registrationFeesTrackId
      paymentHistory {
        edges {
          node {
            transaction {
              status
              trackId
            }
          }
        }
      }
    }
  }
`;

export const GET_RELATED_REGISTRATION = gql`
  query GetRelatedRegistration(
    $year: String!
    $isInternship: Boolean
    $userId: String!
  ) {
    registrations(year: $year, isInternship: $isInternship, userId: $userId) {
      edges {
        node {
          pk
          program {
            name
            startingDate
          }
          status
          totalHours
          paid
          registrationFeesPaid
        }
      }
    }
  }
`;
