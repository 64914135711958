import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import { publicRoutes } from "./publicRoutes";
import { authenticatedRoutes } from "./authenticatedRoutes";
import NotFound from "components/extraPages/NotFound";
import MaintenancePage from "components/extraPages/MaintenancePage";

import LoadingPage from "components/common/components/loading";
import { useAppSelector } from "redux/reducers/hooks";
import { unauthenticatedRoutes } from "./unauthenticatedRoutes";
import UnProtectedRoute from "./UnProtectedRoute";
import PermissionDenied from "components/extraPages/PermissionDenied";
import { accessOnlyEn } from "components/helpers/helper_functions";

const MainRoute = () => {
  const { userLoading, user } = useAppSelector(({ rootAuth }) => rootAuth);

  if (userLoading) {
    return <LoadingPage />;
  }

  let defaultLang = "ar";

  if (accessOnlyEn(user)) defaultLang = "en";

  return (
    <Switch>
      {/* Catch-all only activate this during maintenance */}
      {process.env.REACT_APP_MAINTENANCE && (
        <Route path="*" component={MaintenancePage} />
      )}

      {/* this was added due to existing QR code of the system */}
      <Route path="/programs/ar" exact>
        <Redirect to="/ar" />
      </Route>

      {publicRoutes.map(({ path, Component, key }) => (
        <Route
          key={`prefix-${key}`}
          path={`/:lang(en|ar)/${path}`}
          exact
          //@ts-ignore
          render={(props) => {
            return <Component {...props} />;
          }}
        />
      ))}
      {publicRoutes.map(({ path, key }) => (
        <Route
          key={`non-prefix-${key}`}
          path={`/${path}`}
          exact
          render={({ match, location }) => {
            return (
              <Redirect
                to={{
                  pathname: `/${defaultLang}${match.url}`,
                  // Forwarding the original location's state
                  state: location.state,
                  search: location.search,
                }}
              />
            );
          }}
        />
      ))}
      {authenticatedRoutes.map(({ path, Component, key }) => (
        //@ts-ignore
        <ProtectedRoute
          key={`auth-prefix-${key}`}
          path={`/:lang(en|ar)/${path}`}
          exact
          component={Component}
        />
      ))}
      {unauthenticatedRoutes.map(({ path, Component, key }) => (
        //@ts-ignore
        <UnProtectedRoute
          key={`auth-prefix-${key}`}
          path={`/:lang(en|ar)/${path}`}
          exact
          component={Component}
        />
      ))}
      {unauthenticatedRoutes.map(({ path, key }) => (
        <Route
          key={`non-prefix-${key}`}
          path={`/${path}`}
          exact
          render={({ match, location }) => {
            return (
              <Redirect
                to={{
                  pathname: `/${defaultLang}${match.url}`,
                  // Forwarding the original location's state
                  state: location.state,
                  search: location.search,
                }}
              />
            );
          }}
        />
      ))}
      {authenticatedRoutes.map(({ path, key }) => (
        //@ts-ignore
        <Route
          key={`auth-non-prefix-${key}`}
          path={`/${path}`}
          exact
          render={({ match, location }) => (
            <Redirect
              to={{
                pathname: `/${defaultLang}${match.url}`,
                // Forwarding the original location's state
                state: location.state,
                search: location.search,
              }}
            />
          )}
        />
      ))}
      {unauthenticatedRoutes.map(({ path, key }) => (
        //@ts-ignore
        <Route
          key={`auth-non-prefix-${key}`}
          path={`/${path}`}
          exact
          render={({ match, location }) => (
            <Redirect
              to={{
                pathname: `/${defaultLang}${match.url}`,
                // Forwarding the original location's state
                state: location.state,
                search: location.search,
              }}
            />
          )}
        />
      ))}

      <Route path="/programs" exact>
        <Redirect to="/" />
      </Route>
      <Route path="/en" exact>
        <Redirect to="/" />
      </Route>
      <Route path="/ar" exact>
        <Redirect to="ar/" />
      </Route>
      <Route path="/home" exact>
        <Redirect to="/accounts" />
      </Route>
      {/* Catch-all 404 Route, ensure it's the last route within Switch */}
      <Route path="/permission-denied" component={PermissionDenied} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default MainRoute;
